<template>
  <div id="InsumoHistorico" class="py-3 px-0">
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      :style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold body-1"
        :style="{ color: COR_SUBTITULO }"
      >
        Histórico de Compras
      </p>        
    </v-toolbar-title>

    <v-dialog
      v-model="store_Insumo.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
    </v-dialog>
    
      <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-container class="mt-2 d-flex flex-column">      
      <!-- 1ª Linha - Tabela Historico da Obra -->
      <v-row class="mt-n8 mx-0">
      <!-- Grade  -->
        <v-col cols="12" class="pa-0 mx-0">
          <v-container
            class="container-principal container-rounded rounded-lg mx-0 px-0"
          >
            <!-- Conteiner tabela -------------------------------------- -->
            <v-data-table
              id="virtualScrollTable"
              ref="virtualScrollTable"
              :items="historicoTeste"
              :headers="headers"
              :loading="loading"
              :items-per-page="20"
              :hide-default-footer="true"
              :hide-default-header="isMobile"
              :server-items-length="store_Insumo.count_dados"
              dense
              style="white-space: nowrap"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table white-header"
            >
              <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->

              <!-- Items ---------------------------------------->
              <template #item="{ item }">
                <!-- Linha - Data/Fornecedor/Cotação/Quantidade/valorUni/valorTotal/Frete  -->
                <tr v-if="!isMobile" >
                  <!-- Data de compra -->
                  <td>
                    <div>
                      <span
                        class="mb-1 items_color"
                        >
                        <span style="font-weight: bold"> {{ item.date }} </span>
                          {{
                          item.dt_compra
                            ? formatDate(item.dt_compra)
                            : ""
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo Fornecedor-->
                  <td class="py-3">
                    <div>
                      <span
                        class="items_color d-flex align-center mb-1"
                      >
                        <span 
                          style="font-weight: bold" 
                          class="mr-1"
                        >
                        </span>
                            {{
                              item.nome_fornecedor
                              ? (item.nome_fornecedor)
                              : ""
                            }}
                      </span>     
                    </div>       
                  </td>
                  <!-- Cotação -->
                  <td class="py-3">
                    <div>
                      <span
                        class="items_color d-flex align-center mb-1"
                      >
                        <span 
                        style="font-weight: bold" 
                        class="mr-1"
                        >
                        </span>
                          {{item.cotacao 
                          ? (item.cotacao)
                          : ""
                          }} 
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Quantidade -->
                  <td class="py-3">
                    <div>
                      <span
                      class="items_color d-flex align-center mb-1" 
                      >
                        <span 
                          style="font-weight: bold" 
                          class="mr-1">
                        </span>
                        {{
                          item.quantidade + " " + (store_Insumo.insumo_selecionado.unidade ?? "") 
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Valor Unitario -->  
                  <td class="py-3">
                    <div>
                      <span 
                        class="items_color d-flex align-center mb-1"
                      >
                        <span 
                        style="font-weight: bold" 
                        class="mr-1"
                        >
                        </span>
                        {{
                          "R$ "
                          + formatNumber(item.valor_unitario)
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Valor Total -->
                  <td class="py-3">
                    <div>
                      <span 
                        class="items_color d-flex align-center mb-1" 
                      >
                      <span 
                        style="font-weight: bold" 
                        class="mr-1"
                        >
                        </span>
                        {{
                          "R$ " 
                          + formatNumber(item.quantidade * item.valor_unitario)
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Frete -->
                  <td class="py-3">
                    <div>
                      <span
                      class="items_color d-flex align-center mb-1 " 
                      >
                        <span 
                        style="font-weight: bold" 
                        class="mr-1"
                        >
                        </span>
                        {{
                          "R$ " 
                          + formatNumber(item.frete)
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
                <!-- Fim Campos ---------------->
              </template>
            </v-data-table>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<!-- Fim Grade --------------------------------- -->

<script>

  // import store_ModalObra from "./ModalObra/store_ModalObra";
  // import ModalCadastrarhistorico from "./ModalObra/ModalCadastrarhistorico.vue";
  // import ModalSelecaoPessoa from "../../ModalNegociacao/ModalSelecaoPessoa.vue";
  // import VueCropImage from "../../ComponentesGlobais/CropImage/VueCropImage.vue";
  // import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
  // import { API } from "../../../services/API";
  // import InsumoContainer from "./InsumoContainer.vue";
  // import { computed } from 'vue';
import store_Insumo from "./store_Insumo";
import store_site from "./../../../store/store_site";
import store_ModalNegociacao from '../../ModalNegociacao/store_ModalNegociacao'

import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CNO,
} from "../../../services/constantes";

export default {
  name: "InsumoHistorico",


  data() {
    return {
      store_site      : store_site,
      store_Insumo    : store_Insumo,
      store_ModalNegociacao : store_ModalNegociacao,

      MASK_CNO        : MASK_CNO,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SUBTITULO   : COR_SUBTITULO,
      COR_SECUNDARIA  : COR_SECUNDARIA,
      formatNumber    : formatNumber,
      formatDate      : formatDate,
      nomeIniciais    : nomeIniciais,

      valid           : true,
      alert           : false,
      alert_msg       : "",
      loading         : false,
      insumo_selecionadoAux  : null, // Dados a serem alterados da obra selecionada
      // Cabeçalho da Tabela de historicos
      headers: [
        { text: "Data de Compra", value: "dt_compra", sortable: true,   },
        { text: "Fornecedor", value: "nome_fornecedor", sortable: true,  },
        { text: "Cotação", value: "cotacao", sortable: true, },
        { text: "Quantidade", value: "quantidade", sortable: true,},
        { text: "Valor Unitario", value: "valor_unitario", sortable: true, },
        { text: "Valor Total", value: "valor_total", sortable: true, },
        { text: "Frete", value: "frete", sortable: true,},
      ],
    // Alimentação de testes 
      historicoTeste: [
        {
          dt_compra: "12/12/2024",
          nome_fornecedor: "Fornecedor 1",
          cotacao: "Cotação 1",
          quantidade: 1000,
          valor_unitario: 100,
          valor_total: 1000,
          frete: 100,
        },
        {
          dt_compra: "04/05/2024",
          nome_fornecedor: "Fornecedor 2",
          cotacao: "Cotação 2",
          quantidade: 64,
          valor_unitario: 100,
          valor_total: 1000,
          frete: 100,
        },
        {
          dt_compra: "10/11/2023",
          nome_fornecedor: "Fornecedor 3",
          cotacao: "Cotação 3",
          quantidade: 300,
          valor_unitario: 100,
          valor_total: 1000,
          frete: 100,
        },
        {
          dt_compra: "09/06/2024",
          nome_fornecedor: "Fornecedor 4",
          cotacao: "Cotação 4",
          quantidade: 60,
          valor_unitario: 100,
          valor_total: 1000,
          frete: 100,
        },
        {
          dt_compra: "06/05/2024",
          nome_fornecedor: "Fornecedor 5",
          cotacao: "Cotação 5",
          quantidade: 170,
          valor_unitario: 100,
          valor_total: 1000,
          frete: 100,
        },
      ],
    };
  },

  watch: {
    "store_Insumo.insumo_selecionado"(val) {
      this.atualizaAbas();
    },
  },

  async mounted() {
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Insumo.insumo_selecionado?.cod_obra &&
        this.store_Insumo.insumo_selecionado?.menu_abas
      ) {
        let abas = await this.store_Insumo.menuAbas();
        this.store_Insumo.abas = abas;
      }
    },

    ModalConfirmacao(item){
      this.dialogCancelar = true;
    },

    fecha_alert() {
      this.alert = false;
    },

    updateValorPrevisto(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Insumo.insumo_selecionado.valor_previsto = numericValue;
    },
    
    updateValorRealizado(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Insumo.insumo_selecionado.valor_realizado = numericValue;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    formattedValorPrevisto() {
      // Formata o valor para exibição
      let valor = this.store_Insumo.insumo_selecionado.valor_previsto;
      return formatNumber(valor);
    },
    formattedValorRealizado() {
      // Formata o valor para exibição
      let valor = this.store_Insumo.insumo_selecionado.valor_realizado;
      return formatNumber(valor);
    },
  },
};
</script>

<style scoped>
@media (max-width: 599px) {
  #InsumoHistorico {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#InsumoHistorico::-webkit-scrollbar {
  width: 5px;
}

#InsumoHistorico::-webkit-scrollbar-button {
  padding: 1px;
}

#InsumoHistorico::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#InsumoHistorico::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #InsumoHistorico::-webkit-scrollbar {
    width: initial;
  }

  #InsumoHistorico::-webkit-scrollbar-button {
    padding: initial;
  }

  #InsumoHistorico::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #InsumoHistorico::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.data-table td {
  /* background: #edeff1; */
  color: #616161
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: unset !important; /* Remove a propriedade de cor definida anteriormente */
  
}

