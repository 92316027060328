<template>
  <div id="InsumoEstoque" class="py-3 px-0">
    <v-toolbar-title
      :style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold body-1"
        :style="{ color: COR_SUBTITULO }"
      >
        Estoque
      </p>        
    </v-toolbar-title>
      <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-container class="d-flex flex-column">      
      <!-- 1ª Linha - Tabela Historico da Obra -->
      <v-row class="mt-n10 mx-0">
        <v-container class="mt-2 d-flex flex-column">
          <v-row no-gutter class="pt-0 mb-n7">

            <!-- Disponivel -->
            <v-col class="md-3">
              <v-card 
              elevation="2" 
              color="#fff" 
              class="mx-auto my-4"
              max-width="334"
              height="135"
              expand
              :loading="loading"
              >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="d-flex  justify-space-between">
                    <p class="p-0 m-0 text-h5" style="color: #16c172"> Disponível </p>
                    <v-icon class="mb-4" style="color:#16c172"> mdi-check-circle-outline </v-icon>
                  </div>
                  <v-spacer></v-spacer>
                  <v-list-item-title class="text-h5 mt-4">
                    {{store_Insumo.qtd_disponivel + " " +store_Insumo.insumo_selecionado.unidade}}
                  </v-list-item-title>
                  <v-list-item-subtitle> estão em estoque no momento.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-card>
            </v-col>

            <!-- Uso Previsto -->
            <v-col class="md-3">
              <v-card 
              elevation="2" 
              color="#fff" 
              class="mx-auto my-4"
              max-width="334"
              height="135"
              expand
              :loading="loading"
              >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="d-flex  justify-space-between">
                    <p class="p-0 m-0 text-h5" style="color: #ffc857"> Uso Previsto </p>
                    <v-icon class="mb-4" style="color:#ffc857"> mdi-eye-outline </v-icon>
                  </div>
                  <v-spacer></v-spacer>
                  <v-list-item-title class="text-h5 mt-4">
                    {{store_Insumo.qtd_prevista + " " +store_Insumo.insumo_selecionado.unidade}}
                  </v-list-item-title>
                  <v-list-item-subtitle> estão em estoque no momento.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-card>
            </v-col>

            <!-- Em Falta -->
          <v-col class="md-3">
              <v-card 
              elevation="2" 
              color="#fff" 
              class="mx-auto my-4"
              max-width="334"
              height="135"
              expand
              :loading="loading"
              >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="d-flex  justify-space-between">
                    <p class="p-0 m-0 text-h5" style="color: #db3a34"> Em Falta </p>
                    <v-icon class="mb-4" style="color:#db3a34"> mdi-alert-outline </v-icon>
                  </div>
                  <v-spacer></v-spacer>
                  <v-list-item-title class="text-h5 mt-4">
                    {{store_Insumo.qtd_falta + " " +store_Insumo.insumo_selecionado.unidade}}
                  </v-list-item-title>
                  <v-list-item-subtitle> estão em estoque no momento.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
       <!-- Divisor -->
        <hr class="my-4" style="opacity: 0">
        <v-container
          class="mb-n3 d-flex justify-center"
          style="background-color: #fff; border-radius: 3px; border-bottom: 1px solid #E9E9E9;" 
        >
          <v-row class="mt-n5" align="center" justify="space-between">
              <v-col class="d-flex justify-start">
                <h3
                :style="{ color: COR_SUBTITULO }"
                > 1º Armazem</h3>
              </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
              elevation="0"
              color="#fff"
              @click="TransferirInsumo()"
              >
                <v-icon
                  :style="{color: COR_SUBTITULO}"
                >
                  mdi-swap-horizontal
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
          <!-- 1ª tabela -->
          <v-col cols="12" class="pa-0 mx-0">
            <v-container
              class="container-principal container-rounded rounded-lg mx-0 px-0"
            >
              <!-- Conteiner tabela -------------------------------------- -->
            <v-data-table
              id="virtualScrollTable"
              ref="virtualScrollTable"
              :items="obras"
              :headers="headers"
              :loading="loading"
              :items-per-page="20"
              :hide-default-footer="true"
              :hide-default-header="isMobile"
              :server-items-length="store_Insumo.count_dados"
              dense
              style="white-space: nowrap max-width: 100%; overflow-y: auto"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table white-header"
            >
              <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
              
              <!-- Items ---------------------------------------->
              <template #item="{ item }">
                <!-- Linha - Data/Fornecedor/Cotação/Quantidade/valorUni/valorTotal/Frete  -->
                <tr v-if="!isMobile" >
                  <!-- Código -->
                  <td>
                    <div>
                      <span
                        class="mb-1 ml-3 items_color"
                        >
                        {{item.cod_obra
                          ? (item.cod_obra)
                          : ""
                        }}
                      </span>      
                    </div>
                  </td>
                  <!-- Campo Descricao-->
                  <td class="py-3">
                    <div>
                      <span
                        class="items_color d-flex align-center mb-1"
                      >
                        <span 
                          style="font-weight: bold" 
                          class="mr-1"
                        >
                        </span>
                            {{
                              item.nome_obra
                              ? (item.nome_obra)
                              : ""
                            }}
                      </span>     
                    </div>       
                  </td>
                  <!-- Data Entrada -->
                  <td class="py-3">
                    <div>
                      <span
                        class="items_color d-flex align-center mb-1"
                      >
                      <span style="font-weight: bold"> {{ item.date }} </span>
                          {{
                          item.dt_entrada
                            ? formatDate(item.dt_entrada)
                            : ""
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Quantidade -->
                  <td class="py-3">
                    <div>
                      <span
                      class="items_color d-flex align-center mb-1" 
                      >
                        <span 
                          style="font-weight: bold" 
                          class="mr-1">
                        </span>
                        {{
                          item.quantidade + " " + (store_Insumo.insumo_selecionado.unidade ?? "") 
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Valor Unitario -->  
                  <td class="py-3">
                    <div>
                      <span 
                        class="items_color d-flex align-center mb-1"
                      >
                        <span 
                        style="font-weight: bold" 
                        class="mr-1"
                        >
                        </span>
                        {{
                          "R$ "
                          + formatNumber(item.valor_unitario)
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- Campo de Valor Total -->
                  <td class="py-3">
                    <div>
                      <span 
                        class="items_color d-flex align-center mb-1" 
                      >
                      <span 
                        style="font-weight: bold" 
                        class="mr-1"
                        >
                        </span>
                        {{
                          "R$ " 
                          + formatNumber(item.quantidade * item.valor_unitario)
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
                <!-- Fim Campos ---------------->
              </template>
            </v-data-table>
          </v-container>
        </v-col>
      </v-row>

      <v-container v-if="store_ModalInsumo.dialog_transferir_insumo">
        <v-dialog
          v-model="store_ModalInsumo.dialog_transferir_insumo"
          transition="dialog-bottom-transition"
          max-width="440px"     
          persistent          
          >
          <v-card>
            <ModalTransferirInsumo />
          </v-card>
        </v-dialog>
      </v-container>
    </v-container>
  </div>
</template>

<!-- Fim tabela --------------------------------- -->

<script>

  // import store_ModalObra from "./ModalObra/store_ModalObra";
  // import ModalCadastrarhistorico from "./ModalObra/ModalCadastrarhistorico.vue";
  // import ModalSelecaoPessoa from "../../ModalNegociacao/ModalSelecaoPessoa.vue";
  // import VueCropImage from "../../ComponentesGlobais/CropImage/VueCropImage.vue";
  // import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
  // import { API } from "../../../services/API";
  // import InsumoContainer from "./InsumoContainer.vue";
  // import { computed } from 'vue';
import store_Insumo from "./store_Insumo";
import store_site from "./../../../store/store_site";
import store_ModalNegociacao from '../../ModalNegociacao/store_ModalNegociacao'
import store_ModalInsumo from "./ModalInsumo/store_ModalInsumo";
import ModalTransferirInsumo from './ModalInsumo/ModalTransferirInsumo.vue';

import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CNO,
} from "../../../services/constantes";

export default {
  name: "InsumoEstoque",
  components: {
    ModalTransferirInsumo,
  },
  


  data() {
    return {
      store_site      : store_site,
      store_Insumo    : store_Insumo,
      store_ModalNegociacao : store_ModalNegociacao,
      store_ModalInsumo: store_ModalInsumo,


      MASK_CNO        : MASK_CNO,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SUBTITULO   : COR_SUBTITULO,
      COR_SECUNDARIA  : COR_SECUNDARIA,
      formatNumber    : formatNumber,
      formatDate      : formatDate,
      nomeIniciais    : nomeIniciais,

      dialogCancelar: false,
      dialog_excluir: false,
      dialog_excluir_alert: false,
      dialog_excluir_msg: false,
      dialog_excluir_message: null,
      dialogIndiceCadastro: false,

      obras           : [],

      valid           : true,
      alert           : false,
      alert_msg       : "",
      loading         : false,
      insumo_selecionadoAux  : null, // Dados a serem alterados da obra selecionada
      // Cabeçalho da Tabela de historicos
      "headers": [
        { text: "Código", value: "cod_insumo", sortable: true,   },
        { text: "Descrição", value: "descricao", sortable: true,  },
        { text: "Data Entrada", value: "dt_entrada", sortable: true, },
        { text: "Qtd / Unid", value: "quantidade", sortable: true,},
        { text: "Valor Unitario", value: "valor_unitario", sortable: true, },
        { text: "Valor Total", value: "valor_total", sortable: true, },
      ],
    
    
    };
    
  },

  watch: {
    "store_Insumo.insumo_selecionado"(val) {
      this.atualizaAbas();
    },
  },

  async mounted() {
      store_Insumo.ObrasGet().then(response => {
      console.log("Resposta do servidor:", response);
      this.obras = response;
      });
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Insumo.insumo_selecionado?.cod_obra &&
        this.store_Insumo.insumo_selecionado?.menu_abas
      ) {
        let abas = await this.store_Insumo.menuAbas();
        this.store_Insumo.abas = abas;
      }
    },
     TransferirInsumo() {
      this.store_ModalInsumo.acao = "I";
      this.store_ModalInsumo.transferir_insumo = {};
      this.store_ModalInsumo.dialog_transferir_insumo = true;
    },


    fecha_alert() {
      this.alert = false;
    },

    updateValorPrevisto(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Insumo.insumo_selecionado.valor_previsto = numericValue;
    },
    
    updateValorRealizado(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Insumo.insumo_selecionado.valor_realizado = numericValue;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    formattedValorPrevisto() {
      // Formata o valor para exibição
      let valor = this.store_Insumo.insumo_selecionado.valor_previsto;
      return formatNumber(valor);
    },
    formattedValorRealizado() {
      // Formata o valor para exibição
      let valor = this.store_Insumo.insumo_selecionado.valor_realizado;
      return formatNumber(valor);
    },
  },
};
</script>

<style scoped>
@media (max-width: 599px) {
  #InsumoEstoque {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#InsumoEstoque::-webkit-scrollbar {
  width: 5px;
}

#InsumoEstoque::-webkit-scrollbar-button {
  padding: 1px;
}

#InsumoEstoque::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#InsumoEstoque::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #InsumoEstoque::-webkit-scrollbar {
    width: initial;
  }

  #InsumoEstoque::-webkit-scrollbar-button {
    padding: initial;
  }

  #InsumoEstoque::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #InsumoEstoque::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}
