<template>
  <div id="InsumoCadastro" class="py-3 px-0">
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>
      <v-btn 
        v-if="store_Insumo.acao == 'C'"
        @click="
          store_Insumo.acao = 'E';
          insumo_edicao = {
            ...store_Insumo.insumo_selecionado,
          };
        "
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-2 "
          >mdi-view-headline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span
        >
      </v-btn>
    </v-toolbar-title>

    <v-dialog
      v-model="store_Insumo.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
    </v-dialog>
    <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-form v-model="valid" id="form" ref="form" class="mx-0 mt-3">
      <v-container class="mt-2 d-flex flex-column">
        <!-- 1ª Linha - Tipo do Insumo / Nome / -->
        <v-row no-gutter class="pt-0 mb-n7">
          <!-- Tipo do Insumo -->
          <v-col md="4">
            <v-autocomplete
              v-model="store_Insumo.insumo_selecionado.tipo"
              class="mb-0"
              maxlength="100"
              loader-height="1"
              hide-details="auto"
              label="Tipo do Insumo"
              placeholder="Tipo do Insumo"
              background-color="#fff"
              filled
              outlined
              dense
              autofocus
              :rules="tipoInsumoRules"
              :items="['Etapa', 'Item']"
              :readonly="store_Insumo.acao != 'I'"
              :append-icon="store_Insumo.acao != 'C' ? 'mdi-menu-down': ''"
              >
            </v-autocomplete>
          </v-col>  

          <!-- Nome Insumo -->
          <v-col md="8">
            <v-text-field
              v-model="store_Insumo.insumo_selecionado.nome_insumo"
              class="mb-0"
              maxlength="100"
              loader-height="1"
              label="Nome Insumo"
              hide-details="auto"
              placeholder="Nome Insumo"
              background-color="#FFF"
              light
              filled
              outlined
              dense
              :readonly="store_Insumo.acao == 'C'"
              :rules="nomeInsumoRules"
            ></v-text-field>
          </v-col>
          
        </v-row>
        <!-- 2ª Linha - Unidade / Categoria -->
        <v-row no-gutter class="pt-0 mb-n14" v-if="store_Insumo.insumo_selecionado.tipo != 'Etapa'">
          <!-- Unidade -->
          <v-col md="4">
            <v-autocomplete
              v-model="store_Insumo.insumo_selecionado.unidade"
              item-text="descricao"
              item-value="id"
              loader-height="1"
              label="Unidade"
              placeholder="Unidade"
              background-color="#FFF"
              maxlength="100"
              light
              filled
              outlined
              dense
              :items="arrayUnidades"
              :menu-props="{ maxHeight: '250px' }"
              :readonly="store_Insumo.acao == 'C'"
              :append-icon="store_Insumo.acao != 'C' ? 'mdi-menu-down' : ''"
            ></v-autocomplete>
          </v-col>

          <!-- Categoria -->
          <v-col md="4">
            <v-autocomplete
              v-model="store_Insumo.insumo_selecionado.categoria"
              item-text="descricao"
              item-value="id"
              loader-height="1"
              label="Categoria"
              placeholder="Categoria"
              background-color="#FFF"
              maxlength="100"
              light
              filled
              outlined
              dense
              :items="arrayCategorias"
              :menu-props="{ maxHeight: '250px' }"
              :readonly="store_Insumo.acao == 'C'"
              :append-icon="store_Insumo.acao != 'C' ? 'mdi-menu-down' : ''"
            ></v-autocomplete>  
          </v-col>

          <!-- Valor Unitario -->
          <v-col md="4">
            <VuetifyMoney
              id="valor_unitario"
              ref="valor_unitario"
              dense
              v-model="store_Insumo.insumo_selecionado.valor_unitario"
              cache-items
              flat
              label="Valor Unitário"
              placeholder="Valor Unitário"
              outlined
              filled
              background-color="#FFF"
              clearable
              :readonly="store_Insumo.acao != 'I'"
              :options="{
                locale: 'pt-BR',
                prefix: '',
                suffix: '',
                length: 11,
                precision: 4
              }"
            ></VuetifyMoney>  
          </v-col>
        </v-row>
      </v-container>

      <!-- Rodapé - Botões Salvar / Cancelar -->
      <v-footer
        v-if="store_Insumo.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3 pb-n3"
        fixed
        style="position: static"
      >
        <v-btn
          v-if="store_Insumo.acao == 'E'"
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <router-link
          v-else-if="store_Insumo.acao == 'I'"
          :to="{ name: 'InsumoConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn
            cer
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text
          >
            Cancelar
          </v-btn>
        </router-link>

        <v-btn
          :loading="loading"
          :disabled="!valid"
          @click="CadastraAtualizaInsumo()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog v-model="dialogCancelar" transition="dialog-bottom-transition" max-width="410" class="pa-0">
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Deseja realmente Excluir este Registro?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogCancelar = false">
              Cancelar
            </v-btn>
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="ExcluiEndereco()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_site from "./../../../store/store_site";
import store_Insumo from "./store_Insumo";
import VuetifyMoney from '../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
import store_ModalNegociacao from '../../ModalNegociacao/store_ModalNegociacao'
import ModalSelecaoPessoa from "../../ModalNegociacao/ModalSelecaoPessoa.vue";
import { API } from "../../../services/API";

import {
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CNO,
} from "../../../services/constantes";

import { computed } from 'vue';

export default {
  name: "InsumoCadastro",

  components: {
    VuetifyMoney,
  },

  data() {
    return {
      store_site      : store_site,
      store_Insumo    : store_Insumo,

      MASK_CNO        : MASK_CNO,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SUBTITULO   : COR_SUBTITULO,
      COR_SECUNDARIA  : COR_SECUNDARIA,
      formatDate      : formatDate,
      nomeIniciais    : nomeIniciais,

      valid           : true,
      alert           : false,
      alert_msg       : "",
      loading         : false,

      dialog_crop: false,
      dialogCancelar: false,

      arrayCategorias: ["Mão de Obra", "Material", "Equipamentos", "Administrativo", "Outros"],
      arrayUnidades: ["UN", "CX", "KG", "M²", "M³", "M", "L", "H", "PC", "PCT", "SC", "MT", "ML", "GL", "TON", "CM", "DZ", "LT", "JG", "G"],
      
      // Opcões de Selects estaticos
      opcoes: [{title: "Editar"},{title: "Excluir"}],
      situacao: ["Finalizada", "Em Andamento", "Parada"],
      
      tipoInsumoRules: [(value) => !!value || "Definir o tipo é obrigatório"],
      
      nomeInsumoRules: [(value) => !!value || "O nome do Insumo é obrigatório"],
     
      situacaoRules: [(value) => !!value || "A situação é obrigatória"],
    };
  },

  watch: {
    "store_Insumo.insumo_selecionado"(val) {
      this.atualizaAbas();
    },
  },

  async mounted() {
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Insumo.insumo_selecionado?.cod_obra &&
        this.store_Insumo.insumo_selecionado?.menu_abas
      ) {
        let abas = await this.store_Insumo.menuAbas();
        this.store_Insumo.abas = abas;
      }
    },
  
    async CadastraAtualizaInsumo() {
      const lb_valido = this.$refs.form.validate();
      if(lb_valido) {
        let ls_Rel = null;
        var lo_params = {...JSON.parse(JSON.stringify(this.store_Insumo.insumo_selecionado))};
        if (this.store_Insumo.acao == "I") {
          ls_Rel = await this.store_Insumo.InsumoPost(lo_params);
          this.store_Insumo.insumo_selecionado.cod_insumo = ls_Rel.data.result.data.cod_insumo;
          // console.log(ls_Rel.data.result.data.cod_insumo);
          // console.log(ls_Rel.data.result.data.tipo);
        }
        else if (this.store_Insumo.acao == "E") {
          ls_Rel = await this.store_Insumo.InsumoPut(lo_params);
        }
        if (ls_Rel.status == 200 ) {
          this.store_Insumo.acao = "C";
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
        }
        else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result;
          this.store_site.alert = true;
        }
      }
    },

    cancelar() {
      if (this.store_Insumo.acao == "I") {
        this.insumo_edicao = null;
      } else if (this.store_Insumo.acao == "E") {
        this.store_Insumo.insumo_selecionado = {
          ...this.insumo_edicao,
        };
      }
      this.store_Insumo.acao = "C";
    },
    ModalConfirmacao(item){
      this.dialogCancelar = true;
    },
    fecha_alert() {
      this.alert = false;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#InsumoCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media (max-width: 599px) {
  #InsumoCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#InsumoCadastro::-webkit-scrollbar {
  width: 5px;
}

#InsumoCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#InsumoCadastro::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#InsumoCadastro::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #InsumoCadastro::-webkit-scrollbar {
    width: initial;
  }

  #InsumoCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #InsumoCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #InsumoCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  color: #ff0000;
}
</style>
